<!-- 页面程序 -->
<template>  
    <div class="content">
      <div class="topView">
        <section class="top_img_view">
            <img class="topImage" :src="activityTopImg" />
        </section>
      </div>
      <div class="bottomView">

        <div class="center">
            <!-- 衣物洗护 -->
            <div class="clothing" @click="getServiceDataById(1421)">
                <img class="topImage" :src="activityClothingImg" />
            </div>
            <!-- 家电清洗 -->
            <div class="equipment">
                <!-- <img class="topImage" :src="activityEquipmentImg" /> -->
                <div class="equipment_center">
                    <div class="equipment_list" @click="getServiceDataById(1205)"></div>
                    <div class="equipment_list" @click="getServiceDataById(1206)"></div>
                    <div class="equipment_list" @click="getServiceDataById(1206)"></div>
                </div>
            </div>
            <!-- 家庭保洁 -->
            <div class="cleaning">
                <!-- <img class="topImage" :src="activityCleaningImg" /> -->
                <div class="cleaning_center">
                    <div class="cleaning_list" @click="getServiceDataById(1443)"></div>
                    <div class="cleaning_list" @click="getServiceDataById(1411)"></div>
                </div>
            </div>
        </div>
      
        <div class="porject_recommend">
            <section style="display: flex;justify-content: center;align-items: center; height: 40px; color: white;">
                <span style="width: 50px; height: 1px; background: white;" ></span>
                <span style="margin-left: 20px;margin-right: 20px;">推荐服务</span>
                <span style="width: 50px; height: 1px; background: white;"></span>
            </section>
            <section class="porject_recommend_bottom">
                <div class="porject_recommend_scroll_item" v-for="(item, index) in recommendList" :key="index" @click="getServiceData(item)">
                    <section style="display: flex;flex-direction: column;">
                        <img class="spikeByTime_img" style="border-radius:10px; height: 120px;" :src="item.photoPath" />
                        <span class="spikeByTime_name">{{item.projectName}}</span>
                        <span class="spikeByTime_desc">{{item.description}}</span>
                        <div class="spikeByTime_price">
                            <span class="font16" style="width: 50%;font-weight: 800; color: #EE4908;">￥{{1 > item.oltCount ? (item.minimumNewcomerDiscount/100).toFixed(2) : (item.lowestDiscountPrice/100).toFixed(2)}}</span>
                            <span style="color: #666666;text-decoration: line-through;font-size: 12px;">￥{{(item.theLowestPrice/100).toFixed(2)}}</span>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        <div class="bottom_img">
            <img :src="activityBottomImg" style="width: 100% ; height: 100%; "/>
        </div>
      </div>
    </div>
  </template>
  
  
  <!-- JS交互 -->
  <script>
  import wx from 'weixin-js-sdk';

  export default {
    components: {},
    data() {
      return {
          activityId: 7,
          memberId: this.$route.query.memberId,
          activityTopImg:"http://resources.sxdjcq.com/mini/icon_eleven_top_bg.png",
          activityBottomImg:"http://resources.sxdjcq.com/mini/icon_eleven_bottom_bg.png",
          activityClothingImg:"http://resources.sxdjcq.com/mini/icon_eleven_wash_clothing.png",
          activityEquipmentImg:"http://resources.sxdjcq.com/mini/icon_eleven_wash_equipment.png",
          activityCleaningImg:"http://resources.sxdjcq.com/mini/icon_eleven_wash_cleaning.png",
          activityList:[],
          giftList: [],
          recommendList: [],
      };
    },
    methods: {
      getReferralServiceList() {
          let params = {
              placeAnOrder: 0,
              memberId: this.memberId,
          };
          this.$axios({
              url: this.$requests.member.getNewActiveSalesList,
              method: "post",
              data: params,
          }).then((res) => {
              let wrapper = res.data || {};
              this.recommendList = wrapper.data || [];
          }).catch((e) => {});
      },
      getServiceData(e){
        // let href =  "next://getServiceData" + ',' + e.projectId
        // window.location.href = href;
        this.getServiceDataById(e.projectId)
      },
      getServiceDataById(id){
        let path = '/pages/subPackages/orderSubPackages/servicedetails/servicedetails?projectId=' + id
        //如果需要传递参数的话可以通过 url 路径拼接
        wx.miniProgram.navigateTo({
            url: path 
        });
      }
    },
    mounted() {
      // 获取推荐数据
      this.getReferralServiceList();
    },
  };
  </script>
  
  <!-- class 展示属性 -->
  <style lang="less" scoped>
  .content {
      width: 100%;
      background: linear-gradient(
        to bottom, 
        #4138f4, 
        #b3afff);
  }

    .bottomView{
        width: 100%;
        background: linear-gradient(
          to bottom, 
          #4138f4, 
          #b3afff);
    }

  .topView {
    width: 100%;
    height: calc(100vw/750*671);
    display: flex;
    position: relative;
}
.top_img_view {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}
.top_my_invite {
    position: absolute;
    right: 0;
    top: 100px;
    width: 30px;
    height: 82px;
}

  .topImage{
    width: 100%;
    height: 100%;
  }


  .invite_view {
    position: absolute;
    top: calc(100vw/75*75);
    margin-left: 5%;
    height: calc(90vw/89*82.5);
    width: 90vw;
  }
  .invite_view_bg {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .invite_img_top_btn {
    position: absolute;
    width: 100%;
    height: 82%;
    // background-color: rgb(0,0,0,0);
}

.invite_img_bottom_btn{
  position: absolute;
  width: 100%;
  height: 18%;
  top: 82%;
}


  .project_title_view {
      margin-top: 20px;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .project_title_left_dot {
      width: 9px;
      height: 9px;
      background: #333333;
      box-shadow: 0px 1px 0px 0px rgba(136,100,13,0.88);
      border-radius: 50%;
      margin-right: 20px;
  }
  .project_title_text{
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    text-shadow: 0px 1px 0px rgba(64,26,7,0.88);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .project_title_right_dot{
    width: 9px;
    height: 9px;
    background: #333333;
    box-shadow: 0px 1px 0px 0px rgba(136,100,13,0.88);
    border-radius: 50%;
    margin-left:20px;
  }
  .project_info {
      margin-left: 2%;
      margin-top: 2%;
      width: 96%;
      height: 260px;
      background: #FFF9F1;
      border: 1px solid #A67D1A;
      box-shadow: 0px 1px 11px 2px rgba(139,80,18,0.1500);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
  }
  .project_info_img {
      margin-top: 3%;
      margin-left: 3%;
      width: 94%;
      height: 50%;
      border-radius: 5px;
  }
  .project_info_title {
      margin-top: 3%;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: center;
  }
  .project_info_subtitle {
      margin-top: 6px;
      margin-left: 20px;
      margin-right: 20px;
      font-size: 10px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }
  .project_info_bottom {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
  }
  .project_info_price_subtitle {
      position: absolute;
      left: calc(50vw - 150px);
      color: #EE4908;
      height: 19px;
  }
  
  .project_info_price_title {
      position: absolute;
      left: calc(50vw - 40px);
      color: #666666;
      text-decoration: line-through;
      font-size: 12px;
  }
  .porject_recommend {
      width: 100%;
      height: 230px;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .bottom_img {
      width: 100%;
      margin-top: 20px;
  }
  
  .porject_recommend_bottom {
      height: 205px;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;
      overflow-x: scroll;
      white-space: nowrap;
      overflow-y: hidden;
  }
  .porject_recommend_scroll_item {
      margin-top: 5px;
      margin-right: 5px;
      height: 180px;
      width: 35vw;
      background-color: white;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 1px 9px 1px rgba(179,110,69,0.4600);
      border-radius: 5px;
      overflow: hidden;
      display: inline-table;
  }
  .component_content_price{
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0;
    top: 0;
  }
  .component_content_price_couponFee{
    width: 100%;
    height: 60%;
    font-weight: 800;
    color: #EE4908;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .component_content_price_name{
    width: 100%;
    height: 40% ;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spikeByTime_img {
      width: 100%;
      height: 55%;
  }
  .spikeByTime_name{
      overflow: hidden;
      text-overflow: ellipsis;
      width: 33vw;
      margin-top: 2px;
      margin-left: 1vw;
      font-size: 14px;
      color: #333333;
      font-weight: 500;
  }
  .spikeByTime_desc{
      overflow: hidden;
      text-overflow: ellipsis;
      width: 33vw;
      margin-top: 5px;
      margin-left: 1vw;
      font-size: 11px;
      color: #666666;
      font-weight: 500;
  }
  .spikeByTime_price{
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 20px;
      align-items: baseline;
  }

  .center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .clothing{
    width: 96%;
    height: calc(96vw/677*287);
  }


  .equipment{
    margin-top: 20px;
    width: 96%;
    height: calc(96vw/677*362);
    background-image: url('http://resources.sxdjcq.com/mini/icon_eleven_wash_equipment.png');
    background-size: 96vw calc(96vw/677*362);
  }

  .equipment_center{
    width: 100%;
    height: 100%;
    display: flex;
  }

  .equipment_list{
    width: calc(96vw/3);
    height: 100%;
  }


  .cleaning{
    margin-top: 10px;
    width: 96%;
    height: calc(96vw/677*494);
    background-image: url('http://resources.sxdjcq.com/mini/icon_eleven_wash_cleaning.png');
    background-size: 96vw calc(96vw/677*494);
  }


  .cleaning_center{
    width: 100%;
    height: 100%;
    display: flex;
  }

  .cleaning_list{
    width: calc(96vw/2);
    height: 100%;
  }

  </style>